import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '../translate/index';
import { AuthenticationService } from '../helpers/AuthenticationService';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  isDepartmentLogin: boolean = false;
  isInputShown = false;
  constructor(
    public translator: TranslateService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    
    this.forgetPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      phonenumber:['']
    });
    this.forgetPasswordForm.controls.phonenumber.disable();
  }

  finalSubmit() {
    
    if (this.forgetPasswordForm.invalid) {
      return;
    }
  
    let body = {
      Email: this.forgetPasswordForm.value.username,
      Number: this.forgetPasswordForm.value.phonenumber == undefined ? "" : this.forgetPasswordForm.value.phonenumber
    }
    this.authenticationService.postForgetPassword(body)
      .subscribe({
        next: (res) => {
          
          if (res == 202) {
            this.toastr.info('Check your given Email/Mobile Number', 'Forget Password');
            this.router.navigate(['/login']);
          }
          else{
            
            this.toastr.info('Please Enter the Email Address For Forgot Password','Forget Password');
            document.getElementById('PhoneNumber').setAttribute('readonly','readonly');
            this.isInputShown = true;
            this.forgetPasswordForm.controls.phonenumber.enable();
          }
        },
        error: error => {
          
          this.toastr.error("Enter Valid Email or Phone Number", 'Forget Password');
        }
      });
  }

  changeLoginType() {
    this.isDepartmentLogin = !this.isDepartmentLogin;
  }

 
}
