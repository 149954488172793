import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../helpers/AuthenticationService';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from "rxjs/Observable";

import "rxjs/add/observable/timer";
import "rxjs/add/operator/map";
import "rxjs/add/operator/take";
@Component({
  selector: 'app-otpverify',
  templateUrl: './otpverify.component.html',
  styleUrls: ['./otpverify.component.css']
})
export class OtpverifyComponent implements OnInit {
  otpForm: FormGroup;
  email: any;
  loginpagename: string = "";
  loginpassword: string = "";
  department: boolean = false;
  resendOtp: boolean = false;
  countDown;
  counter = 300;
  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    
    this.OtpForm();
    let { data, pagename, password, dept } = window.history.state;

    if(data==undefined){
this.router.navigate(['login'])
    }
    this.email = data;
    this.loginpagename = pagename;
    this.loginpassword = password;
    this.department = dept;
    this.countDown = this.countDownfun(this.counter)

  }
  OtpForm() {
    this.otpForm = this.fb.group({
      otp: ['', Validators.required]
    })
  }

  finalSubmit() {
    
    if (this.otpForm.invalid) {
      return;
    }
    let body = {
      emailAddress: this.email,
      otp: this.otpForm.value.otp,
      role: "Candidate"
    }
    this.authenticationService.postOtpVerify(body)
      .subscribe({
        next: (res) => {
          if (res.statusCode == 200) {
            // alert(res.message)
            this.toastr.success(res.message, 'OTP Verification');
            if (this.loginpagename == "login") {
              this.logindetails();
            }
            else {
              this.router.navigate([`login`])
            }
          }
          else if (res.statusCode == 208) {
            // alert(res.message)
            this.toastr.error(res.message, 'OTP Verification');
          }
        },
        error: error => {
          // alert(error)
          this.toastr.error(error.statusText, 'OTP Verification');
        }
      });
  }
  logindetails() {
    let body = {
      userName: this.email,
      password: this.loginpassword,
      roleName: this.department ? "DLC" : "Candidate",
    }
    this.authenticationService.postLogin(body)
      .subscribe({
        next: (sel) => {
          if (sel) {
            this.authenticationService.setToken(sel);
            let validate = this.authenticationService.getToken();
            if (validate.token) {
              
              if (sel.roleName == "DLC") {
                this.router.navigate(['layout/division/districtdepartment'])
              }
              else if (sel.roleName == "Candidate") {
                this.router.navigate(['layout/users/jobapplication/personalinfo'])
              }
            }
          }
        },
        error: error => {
          if (error.status == 401) {
            
            this.toastr.warning(error.error, 'Login');
            if (error.error) {
              this.router.navigate(['otpverify']);
            }
          }
          else {
            this.toastr.error(error.statusText, 'Login');
          }
        }
      });
  }

  ResendOtpfunc(){
    
    let body = {
      EmailAddress: this.email,
      Role: "Candidate"
    }
    this.authenticationService.postResendOTP(body)
      .subscribe({
        next: (res) => {
          
          if (res.statusCode == 200) {
            
            this.toastr.info('OTP sent successfully!', 'OTP Verification');
          }
        },
        error: error => {
          
          this.toastr.error(error.statusText, 'OTP Verification');
        }
      });
  }

  countDownfun(sec) {
    
    return (Observable.timer(0, 1000)
      .take(sec)
      .map(() => {
        let value = --sec
        if (value == 0) {
          this.resendOtp = true;
        }
        const minutes: number = Math.floor((value % 3600) / 60);
        return (
          ("00" + minutes).slice(-2) +
          ":" +
          ("00" + Math.floor(value - minutes * 60)).slice(-2)
        );
      }));
  }
  ChangeResendOtp() {
    this.resendOtp = !this.resendOtp;
    this.countDown = this.countDownfun(this.counter)
    this.ResendOtpfunc();
  }

  keyPress(e){
    var keycode = e.which;
      if (!(e.shiftKey == false && (keycode == 46 || keycode == 8 || keycode == 37 || keycode == 39 || (keycode >= 48 && keycode <= 57)))) {
          e.preventDefault();
      }
  }

}
