import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { OtpverifyComponent } from './otpverify/otpverify.component';
import { LayoutComponent } from './layout/layout.component';
import {AdmitcardComponent} from './admitcard/admitcard.component';
import { AuthGuardService as AuthGuard,AuthLoginService } from './helpers/auth-guard.service';
import { CallletterComponent } from './callletter/callletter.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthLoginService]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthLoginService]
  },
  {
    path:'admitcard',
    component:AdmitcardComponent
  },
  {
    path:'callletter',
    component:CallletterComponent
  },
  {
    path: 'forgetpassword',
    component: ForgetPasswordComponent,
  },
  {
path:'otpverify',
component:OtpverifyComponent
  },
  {
    path: 'layout',canActivate: [AuthGuard], component: LayoutComponent, children: [
        {
            path: 'division',
            loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule)
        },
        {
            path: 'users',
            loadChildren: () => import('./candidate/candidate.module').then(m => m.CandidateModule),
        }
    ]
},
  { path: '', component: LoginComponent,  canActivate: [AuthLoginService],pathMatch: 'full' },
  { path: '**', component: LoginComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ enableTracing: false, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
