<!-- <ul>
  <li>
    <a [routerLink]="'/login'" routerLinkActive="active"> Login </a>
  </li>
  <li>
    <a [routerLink]="'/register'" routerLinkActive="active"> Register </a>
  </li>
  <li>
    <a [routerLink]="'/layout'" routerLinkActive="active"> layout </a>
  </li>
</ul> -->

<div class="content" role="main">
  <router-outlet></router-outlet>
</div>
<app-loader-service></app-loader-service>