import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '../translate/index';
// import { DownloadService } from "./download.service";
// import { Download } from "./download";
import { AuthenticationService } from '../helpers/AuthenticationService';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import{DeviceDetectorService} from 'ngx-device-detector'
import {DomSanitizer} from '@angular/platform-browser'
 

 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  isDepartmentLogin: boolean = false;
  captchaValue: any;
  captchaValueCompare: any;
  captcha = false;
  ipAddress:any;
  info : any;
  fileUrl;
  constructor(public translator: TranslateService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private device : DeviceDetectorService,
    private sanitizer:DomSanitizer,

    ) {this.info  = this.device.getDeviceInfo()
 }

  loginModel = {
    userid: '',
    password: ''
  }

  ngOnInit(): void {

    // slides = {
    //   name: "Mobile Cross-Platform from a Progressive Perspective",
    //   url:
    //     "file:///E:/Tirupura/TJPUI_MultiTaskingStaffGroupD/src/assets/images/ExamNotificationofGrpC.pdf"
    // };
    //  Download Pdf File
    // const data = 'assets/images/ExamNotificationofGrpC.pdf'
    // const blob = new Blob([data],{type : 'application/pdf'});

    // this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      captcha: ['', [Validators.required]]
    });
    this.GenerateCaptcha();
    this.getIp();
    //this.getBrowserClient();
  }
  get f() { return this.loginForm.controls; }

getIp(){
  
  this.authenticationService.getIpAddress().subscribe((res:any)=>{
this.ipAddress = res.ip;
  })
}
getBrowserClient(){
  
}

download(){

}

  loginSubmit() {
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    if (!this.ValidCaptcha()) {
      return;
    }
    
    let body = {

      userName: this.loginForm.value.email,
      password: this.loginForm.value.password,
      roleName: this.isDepartmentLogin ? "DLC" : "Candidate",
      deviceId : this.ipAddress,
      browserVersion: this.info.browser_version,
      osVersion : this.info.os_version,
      browserName : this.info.browser,
      os : this.info.os
    }
    this.authenticationService.postLogin(body)
      .subscribe({
        next: (sel) => {
          if (sel) {
            
            this.authenticationService.setToken(sel);
            let validate = this.authenticationService.getToken();

            if (validate.token) {
             
              if (sel.roleName == "DLC") {
                this.toastr.success('Login Success','Login');
                this.router.navigate(['layout/division/districtdepartment'])
              }
              else if (sel.roleName == "Candidate") {
                this.toastr.success('Login Success','Login');
                this.router.navigate(['layout/users/userdashboard'])
              }
            }
          }
          else{
            this.toastr.error('Username/Password Is Incorrect','Login');
          }
        },
        error: error => {
          if (error.status == 401) {
           
            if (error.error == 'invalid_scope') {
        
              this.router.navigate(['otpverify']);
             this.router.navigate([`otpverify`],{state:{data:this.loginForm.value.email,pagename:"login",password:this.loginForm.value.password,dept:this.isDepartmentLogin}})//,skipLocationChange: true
            }else{
              this.toastr.error('Username/Password invalid', 'Login');
            }
          }
          else {
            this.toastr.error('Username/Password Is Incorrect','Login');
          }
        }
      });

  }
  changeLoginType() {
    this.isDepartmentLogin = !this.isDepartmentLogin;
  }
  // captcha start
  GenerateCaptcha() {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    //var chr3 = Math.ceil(Math.random() * 10) + '';
    var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
    var captchaCode = str + chr1 + ' ' + chr2;
    this.captchaValue = captchaCode;
  }
  ValidCaptcha() {
    var str1 = this.removeSpaces(this.captchaValue);
    var str2 = this.loginForm.value.captcha;
    if (str1 == str2) {
      return true;
    }
    else {
      this.toastr.error('Incorrect Verification Code', 'Verification');
      return false;
    }
  }
  removeSpaces(string) {
    return string.split(' ').join('');
  }
  // captcha end

  // show video start
  isShowVideos = false;
  ShowVideos(){
    this.isShowVideos = true;
  }
// show video end
}
