import { Component, OnInit } from '@angular/core';
import { LoaderServiceService } from "./loader-service.service";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-loader-service',
  templateUrl: './loader-service.component.html',
  styleUrls: ['./loader-service.component.css']
})
export class LoaderServiceComponent implements OnInit {
  loading: boolean = false;
  loadingSubscription: Subscription;
  constructor(private loader: LoaderServiceService) { }
  ngOnInit(): void {
    this.loadingSubscription = this.loader.currentLoginStatus.subscribe((values) => {
      this.loading = values;
    })
  }
}
