import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { finalize, catchError } from 'rxjs/operators';
import { LoaderServiceService } from "../loader-service/loader-service.service";
import {HttpClient} from  '@angular/common/http'; 

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  activaterequest: number = 0;

  constructor(public auth: AuthService, private loader: LoaderServiceService,private http:HttpClient) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.auth.getusersession();
    const isLoggedIn = user != "" && user != null;
    const isApiUrl = request.url.startsWith(environment.apiAuthUrl);
    let validToken = isLoggedIn ? { Authorization: `Bearer ${user}`, ContentType: 'application/json' } : { ContentType: 'application/json' }
    if (isApiUrl) {
      if (this.activaterequest == 0) {
        this.loader.startLoading();
      }
      this.activaterequest++;
      request = request.clone({
        setHeaders: validToken
      });
    }

   
    //Response
    return next.handle(request).pipe(
      catchError(err => {
        if ([401].includes(err.status)) {
          // auto logout if 401 or 403 response returned from api
          this.auth.logout();
        }
        this.loader.stopLoading();
        return throwError(err);
      }),
      finalize(() => {
        this.activaterequest == 0 ? 0 : this.activaterequest--;
        if (this.activaterequest == 0) {
          this.loader.stopLoading();
        }
      })
    );
  }
}