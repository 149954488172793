<!-- =========header section========= -->
<section class="header">
    <div class="top">
        <div class="govt_logo">
            <div style="width: fit-content;margin: auto;text-align: center;">
                <img src="assets/images/indianLogo.jpg">
            </div>
            <h4>
                <div>GOVERNMENT OF TRIPURA</div>
                <div> JOINT RECRUITMENT BOARD TRIPURA (JRBT)</div>
                <div>DIRECTORATE OF EMPLOYMENT SERVICES & MANPOWER PLANNING</div>
                <div>AGARTALA, TRIPURA</div>
            </h4>
        </div>
    </div>
</section>

<!-- =========header section end========= -->
<!-- =========body section========= -->
<section class="body">
    <div class="main_cnt">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-8 govt_logo left_img">
                    <img src="../../assets/images/loginImg2.jpg" class="mt-3" width="100%" height="230px" alt="">
                    <ul>
                        <div class="waviy">
                            <span style="--i:1">N</span>
                            <span style="--i:2">O</span>
                            <span style="--i:3">T</span>
                            <span style="--i:4">E</span>
                            <span style="--i:5">S</span>
                            <span style="--i:6">.</span>
                            <span style="--i:7">.</span>
                            <span style="--i:8">.</span>
                        </div>
                        <li>Intending candidates should register first in order to fill-up online application for various posts notified by JRBT.</li>
                        <li>Before filling up the online application form the candidates should go through detailed advertisement, corrigendum or Addendum issued by JRBT in ,<a href=" http://employment.tripura.gov.in">http://employment.tripura.gov.in</a></li>
                        <li>Contact 0381-2326782 for assistance between 10:30 am to 5:00 pm on all working days.</li>
                        <li>For any query or help, you may write us to  jrbtripura.rect2020@gmail.com. Please mention your name and registered mobile no while writing to us.</li>
                    </ul>
                </div>
                <div class="col-md-4 Login_form">
                    <div class="form">
                        <h6>Forget Password</h6>
                        <hr>
                        <form [formGroup]="forgetPasswordForm" (ngSubmit)="finalSubmit()">
                            <div class="form-group">
                                <label>Email Id<span style="color:#ff0000">*</span></label>
                                <input type="email" formControlName="username" placeholder="Enter Your Registered Email Id" class="form-control" id="PhoneNumber"/>
                                <div *ngIf="forgetPasswordForm.controls['username'].invalid && (forgetPasswordForm.controls['username'].dirty || forgetPasswordForm.controls['username'].touched)" class="alert alert-danger">
                                    <div></div>
                                    <div *ngIf="forgetPasswordForm.controls['username'].errors.required">
                                         Email is required.
                                    </div>
                                    <!-- <div *ngIf="forgetPasswordForm.controls['username'].errors.email">
                                        Enter Valid PhoneNumber
                                    </div> -->
                                </div>
                                <!-- <div [hidden]="!isInputShown">
                                <label>Email Id<span style="color:#ff0000">*</span></label>
                                <input type="email" formControlName="phonenumber" placeholder="Type Your Email Id" class="form-control" />
                                <div *ngIf="forgetPasswordForm.controls['phonenumber'].invalid && (forgetPasswordForm.controls['username'].dirty || forgetPasswordForm.controls['username'].touched)" class="alert alert-danger">
                                    <div></div>
                                    <div *ngIf="forgetPasswordForm.controls['phonenumber'].errors.required">
                                         Email Id is required.
                                    </div>
                                    <div *ngIf="forgetPasswordForm.controls['phonenumber'].errors.email">
                                        Enter Valid Email Id
                                    </div>
                                </div>
                            </div> -->
                            </div>
                       
                            <div class="form-group">
                                <div style="  height: 30px;">
                                    <button class="btn btn-primary" (ngSubmit)="finalSubmit()" [disabled]="forgetPasswordForm.pristine || forgetPasswordForm.invalid" style="float: right;padding: 0px 15px;margin-bottom: 10px;">
                                        Get Verification Code</button>
                                </div>
                                <!-- <label class="info" >Please check your email to reset the password</label> -->

                            </div>
                           
                        </form>
                        
                    </div>
                    <div>
                        <p>Note : Those who have not given their Email Id at the time of registration, please contact JRBT</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>