import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CandidateService } from '../candidate/candidate.service';
@Component({
  selector: 'app-admitcard',
  templateUrl: './admitcard.component.html',
  styleUrls: ['./admitcard.component.css']
})
export class AdmitcardComponent implements OnInit {
  Acknowledgement: string;
  baseUrl:any;
  constructor(private toastr: ToastrService,private candidateService:CandidateService) { }

  ngOnInit(): void {
    this.baseUrl = this.candidateService.getUrl()
  }
  generateHallTicket(){
    
    if (this.Acknowledgement == undefined) {
      this.toastr.info("Please Enter the AcnowldgementNumber",'Admit Card');
      return;
    }
    
    this.candidateService.getHallTicketGenerate(this.Acknowledgement).subscribe({
      next:(data)=>{
        
        if (data.statusCode ==200) {
          this.toastr.success('Your Admit Card is Generating Download Shortly','User Dashboard');
        }
        else if (data.statusCode == 901) {
          this.toastr.warning("Your are Not Eligible to download Hall Ticket",'User Dashboard');
        }
        else if (data.statusCode == 608) {
          this.toastr.warning("Please get in touch with Joint Recruitment Board Tripura for your Admit Card",'User Dashboard')
        }
        else{
          this.toastr.warning("Your are Not Eligible to download Hall Ticket",'User Dashboard');
        }
      },
      error: err => {
        
        if (err.status == 401) {
         this.toastr.warning("Please Try Again Later After Somtimes");
        }
        else {
          this.toastr.warning("Please Try Again Later After Somtimes");
        }
      }
    })

}
}
