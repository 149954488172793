import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CandidateService } from '../candidate/candidate.service';

@Component({
  selector: 'app-callletter',
  templateUrl: './callletter.component.html',
  styleUrls: ['./callletter.component.css']
})
export class CallletterComponent implements OnInit {

  Acknowledgement: any;
  baseUrl: any;
  DateOfBirth: any;

  constructor(private toastr: ToastrService, private candidateService: CandidateService) { }

  ngOnInit(): void {
    this.baseUrl = this.candidateService.getUrl()
  }
  generateCallLetter() {
    
    if (this.Acknowledgement == undefined) {
      this.toastr.info("Please Enter the Roll Number", 'Call Letter');
      return;
    }
    else if(this.DateOfBirth == undefined){
      this.toastr.info("Please Enter the Date Of Birth", 'Call Letter');
      return;
    }
    
    this.candidateService.getCallLetterGenarate(this.Acknowledgement,this.DateOfBirth).subscribe({
      next: (data) => {
        
        if (data.statusCode == 200) { 
          this.toastr.success('Your Call Letter is Generating Download Shortly', 'User Dashboard');
        }
        else if (data.statusCode == 604) {
          this.toastr.warning("Your are Not Eligible to download Call Letter", 'User Dashboard');
        }
        else if(data.statusCode == 498)
        {
          this.toastr.warning("Interview Call Letter Download for Group C posts is closed now", 'User Dashboard');
        }
        else if(data.statusCode == 705){
          this.toastr.warning("Date Of Birth is Not Match With the Given Roll Number",'User Dashboard');
        }
        else if (data.statusCode == 608) {
          this.toastr.warning("Please get in touch with Joint Recruitment Board Tripura for your Call Letter", 'User Dashboard')
        }
        else {
          this.toastr.warning("Your are Not Eligible to download Call Letter", 'User Dashboard');
        }
        
      },
      error: err => {
        
        if (err.status == 401) {
          this.toastr.warning("Please Try Again Later After Somtimes");
        }
        else {
          this.toastr.warning("Please Try Again Later After Somtimes");
        }
      }
    })
  }

}
