import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {HttpClient} from  '@angular/common/http'; 

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private authService: AuthService,private http:HttpClient) {
    }

    public getIpAddress(){
      return this.http.get("http://api.ipify.org/?format=json");
    }

    //login start
    postLogin(body: any) {
        return this.authService.post("/Account/Authendicate", body);
    }

    setToken(data) {    
        let session = this.authService;
        session.setsession("tjpAppToken", data.accessToken);
        session.setsession("userid", data.userId);
    }

    getToken() {
        let session = this.authService;
        let token = session.getsession("tjpAppToken");
        let userid = session.getsession("userid");
        let res = {
            token,
            userid
        }
        return res;
    }
    //login end


    //register start
    postRegister(body: any) {
        return this.authService.post("/Account/RegisterCandidate", body);
    }

    //register end

    //otpverify start
    postOtpVerify(body: any) {
        return this.authService.post("/Account/VerifyOTP", body);
    }
    //otpverif end

    //ForgetPassword start
    postForgetPassword(body: any) {
        return this.authService.post("/Account/ForgetPassword", body);
    }
    //ForgetPassword end

    //ResendOTP start
    postResendOTP(body: any) {
        return this.authService.post("/Account/ResendOTP", body);
    }
    //ResendOTP end

}

