<!-- =========header section========= -->

<!-- <section class="header">
    <div class="top">
        <div class="govt_logo">
            <img src="assets/images/tripura_symbol.png">
            <h4>Government of Tripura</h4>
        </div>
        <div class="flex_grow"></div>
        <div class="cm_pto">
            <img src="assets/images/Tripura-Chief-Minister.jpg">
        </div>
    </div>
</section> -->

<!-- =========header section end========= -->

<!-- =========header section========= -->

<section class="header">
    <div class="top">
        <div class="govt_logo">
            <div style="width: fit-content;margin: auto;text-align: center;">
                <img src="assets/images/indianLogo.jpg">
            </div>
            <div class="Logo_title">
                <h4>
                    <div>GOVERNMENT OF TRIPURA</div>
                    <div> JOINT RECRUITMENT BOARD TRIPURA (JRBT)</div>
                    <div>DIRECTORATE OF EMPLOYMENT SERVICES & MANPOWER PLANNING</div>
                    <div>AGARTALA, TRIPURA</div>
                </h4>
                <!-- <h3>JOINT RECRUITMENT BOARD TRIPURA</h3>
                <p>directorate of employment service & manpower planning, govt of tripura</p> -->
            </div>
        </div>
        <div class="flex_grow"></div>
        <div class="cm_pto">
            <!-- <img src="assets/images/Tripura-Chief-Minister.jpg"> -->
        </div>
    </div>
</section>

<div class="noti">
    <marquee class="web_marquee">
        Age limit for direct recruitment is 18 to 41 years as on 31st December 2020; Upper age limit is relaxable by 5 years in case of ST/SC/PwDs(PH)/ Government servant candidates. Due to pandemic caused by COVID-19, an additional age ralaxation of 1 years
        is allowed to all categories of candidates (Unreserved/reserved candidates and Government servants) as per State Government Memorandum vide No.F.20(1)/-GA(P&T)/18 dated 15th July 2020. Candidates from among the discharged 10,323 ad-hoc teachers
        can apply regardless of their age as per State Government Memorandum vide N0.F.20(3)-GA(P&T)/2020 dated 05th Novernment 2020.
    </marquee>
</div>

<!-- =========header section end========= -->

<div class="Layout_header">
    <div class="container">
        <!-- <ul>
            <li>
                <a [routerLink]="'users/userdashboard'" routerLinkActive="active"> Home </a>
            </li>
            <li>
                <a [routerLink]="'users/jobapplication'" routerLinkActive="active"> Application </a>
            </li>
            <li>
                <a [routerLink]="'users/howtoapply'" routerLinkActive="active"> How To Apply </a>
            </li>
            <li>
                <a [routerLink]="'users/notify'" routerLinkActive="active"> Notification </a>
            </li>
            <li>
                <a [routerLink]="'users/hallticket'" routerLinkActive="active"> Hall Ticket </a>
            </li>
            <li>
                <a [routerLink]="'users/contactinfoteam'" routerLinkActive="active"> Contact Ifo </a>
            </li>
            <li>
                <a href="" (click)="logout()" routerLinkActive="active"> Log Out </a>
            </li>
        </ul> -->
        <section class="navbar">

            <nav class="navbar navbar-expand-sm justify-content-center">
                <div class="mob_marquee">
                    <div class="mob_Humberger_menu" (click)="showMobileViewMenus()"><i class="fa fa-bars" aria-hidden="true"></i></div>
                    <marquee class="mob_marquee">
                        Age limit for direct recruitment is 18 to 41 years as on 31st December 2020; Upper age limit is relaxable by 5 years in case of ST/SC/PwDs(PH)/ Government servant candidates. Due to pandemic caused by COVID-19, an additional age ralaxation of 1 years
                        is allowed to all categories of candidates (Unreserved/reserved candidates and Government servants) as per State Government Memorandum vide No.F.20(1)/-GA(P&T)/18 dated 15th July 2020. Candidates from among the discharged 10,323
                        ad-hoc teachers can apply regardless of their age as per State Government Memorandum vide N0.F.20(3)-GA(P&T)/2020 dated 05th Novernment 2020.

                    </marquee>
                </div>
                <ul class="navbar-nav web_menu_view">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="'users/userdashboard'" routerLinkActive="active">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;">About Us</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">
                            <div class="dropdown">
                                <button class="dropbtn">Apply Online 
                          <i class="fa fa-caret-down"></i>
                        </button>
                                <div class="dropdown-content">
                                    <a [routerLink]="'users/howtoapply'" routerLinkActive="active">How to Apply</a>
                                    <a [routerLink]="'register'" routerLinkActive="active">New User Registration</a>
                                    <a [routerLink]="'login'" routerLinkActive="active">Log in</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <div class="dropdown">
                                <button class="dropbtn">Scheme of Exam
                        <i class="fa fa-caret-down"></i>
                      </button>
                                <div class="dropdown-content">
                                    <a href="#">Syllabus for Written Exam</a>
                                    <a href="#">Interview</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link">
                            <div class="dropdown">
                                <button class="dropbtn" [routerLink]="'users/notify'" routerLinkActive="active">Notification
                        <i class="fa fa-caret-down"></i>
                      </button>
                                <div class="dropdown-content">
                                    <a href="#">Exam</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="'users/contactinfoteam'" routerLinkActive="active">Terms/Condition</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">
                            <div class="dropdown">
                                <button class="dropbtn">Downloads
                        <i class="fa fa-caret-down"></i>
                      </button>
                                <div class="dropdown-content">
                                    <a href="#">Prospectus cum Instruction</a>
                                    <a href="#">Certificate (COVID-19 declaration)</a>
                                    <a [routerLink]="'users/hallticket'" routerLinkActive="active">Admit card/ Call letter</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" style="cursor: pointer;" (click)="logout()" routerLinkActive="active">Logout</a>
                    </li>
                </ul>
            </nav>

        </section>


        <!-- mobile view menus start -->
        <ul class="navbar-nav mob_menu_view" *ngIf="isShowMobileViewMenus">
            <li class="nav-item">
                <a class="nav-link" [routerLink]="'users/userdashboard'" routerLinkActive="active">Home</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;">About Us</a>
            </li>
            <li class="nav-item">
                <a class="nav-link">
                    <div class="dropdown">
                        <button class="dropbtn" (click)="showFirstDroupDown()">Apply Online <i class="fa fa-caret-down"></i>
                </button>
                        <div class="dropdown-content" *ngIf="isShowFirstDroupDown">
                            <a [routerLink]="'users/howtoapply'" routerLinkActive="active">How to Apply</a>
                            <a [routerLink]="'register'" routerLinkActive="active">New User Registration</a>
                            <a [routerLink]="'login'" routerLinkActive="active">Log in</a>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link">
                    <div class="dropdown">
                        <button class="dropbtn" (click)="showSecondDroupDown()">Scheme of Exam <i class="fa fa-caret-down"></i>
              </button>
                        <div class="dropdown-content" *ngIf="isShowSecondDroupDown">
                            <a>Syllabus for Written Exam</a>
                            <a>Interview</a>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link">
                    <div class="dropdown">
                        <button class="dropbtn" (click)="showThirdDroupDown()" [routerLink]="'users/notify'" routerLinkActive="active">Notification<i class="fa fa-caret-down"></i></button>
                        <div class="dropdown-content" *ngIf="isShowThirdDroupDown">
                            <a>Exam</a>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="'users/contactinfoteam'" routerLinkActive="active">Terms/Condition</a>
            </li>
            <li class="nav-item">
                <a class="nav-link">
                    <div class="dropdown">
                        <button class="dropbtn" (click)="showDownloadDroupDown()">Downloads<i class="fa fa-caret-down"></i></button>
                        <div class="dropdown-content" *ngIf="isShowDownloadDroupDown">
                            <a>Prospectus cum Instruction</a>
                            <a>Certificate (COVID-19 declaration)</a>
                            <a [routerLink]="'users/hallticket'" routerLinkActive="active">Admit card/ Call letter</a>
                        </div>
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" (click)="logout()" routerLinkActive="active">Logout</a>
            </li>
        </ul>
        <!-- mobile view menus end -->
    </div>
</div>

<p class="mandatory-txt"> *marked fields are mandatory</p>
<router-outlet></router-outlet>