import { Injectable } from '@angular/core';
import { AuthService } from '../helpers/auth.service';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  private tabactive:number = 1;
  private tabstatusStatus = new BehaviorSubject(this.tabactive);
  currenttabStatus = this.tabstatusStatus.asObservable();
  constructor(private authService: AuthService) { }

getSession(){
  
 return this.authService.getsession("userid");
}
  //personalinfo start

  getSocialCategory(){
    return this.authService.get(`/CommonMaster/GetSocialCategoryDropDown`)
      }

      postData(body:any){
        return this.authService.post(`/Candidate/SubmitCandidateApplyForm`,body)
      }

      getPersonalInfo(ids){
    return this.authService.get(`/Candidate/GetCandidateApplyForm?applicationId=${ids.appid}`)
      }
      getState(){
        return this.authService.get("/LocationMaster/GetStateDropDown")
      }

  //personalinfo end 


  //contactinfo start
  getDistrict(){
    return this.authService.get("/LocationMaster/GetDistrictDropDown")

      }
      getSubDivision(){
        return this.authService.get("/LocationMaster/GetSubDivitionDropDown")
    
          }
          getBlock(){
            return this.authService.get("/LocationMaster/GetBlockDropDown")
        
              }
              getPo(){
                return this.authService.get("/LocationMaster/GetPostOfficeDropDown")
               
            
                  }
                  getPs(){
                  
                    return this.authService.get("/LocationMaster/GetPoliceStationDropDown")
                      }
  //contactinfo end 

  //qualificationinfo start
  blackListGet(){
return this.authService.get("/CommonMaster/GetCandidateDeclarationDropDown")
  }

  qldropdownlist(appid){
    return this.authService.get(`/Candidate/GetQualificationInfoView?Applicationid=${appid}`)
      }
  //qualificationinfo end

  // self declaration start
  getPaymentCandidateInfo(idv){
    return this.authService.get(`/Candidate/GetPaymentCandidateInfo?applicationId=${idv.appid}`)
  }
  getCandidatePaymentOrder(id){
    
    return this.authService.get(`/Candidate/GetCandidatePaymentOrder?applicationId=${id}`)
  }
  paymentSuccess(body:any){
    
    return this.authService.post("/Candidate/PaymentsubmitSuccess",body)
  }
  PaymentFailure(body:any){
    
    return this.authService.post("/Candidate/PaymentsubmitFailure",body)
  }

  postMedia(body:any){
    return this.authService.mediaPost("/Candidate/SubmitCandidatecertificate",body)
  }
  postMediamissing(body:any){
    return this.authService.missingmediaPost("/Candidate/SubmitMissingCandidatecertificate",body)
  }
  popupdta(id){
    
    return this.authService.get(`/Candidate/GetJobNameInfoView?applicationid=${id}`)
  }
  // self declaration end

  //common
  getUserMail(){
    return this.authService.get("/Candidate/GetCandidateInfoView")
  }
  getPaymentDtl(id){
    return this.authService.get(`/Candidate/ISDeclaration?applicationId=${id}`)
  }
  
  nexttabactive(data){
    this.tabstatusStatus .next(data)
  }
  //

  // summary page start
  acknowledgeDownload(id,appid){
    return this.authService.get(`/Candidate/FinalApplicationSlipDownload?id=${id}&applicationId=${appid}`)
  }
  paySlipDownload(id,appid){
    return this.authService.get(`/Candidate/PaymentSlipDownload?id=${id}&applicationId=${appid}`)
  }
  hallTicketDownload(id,appid){
    return this.authService.get(`/Candidate/AdmitCardSlipDownload?id=${id}&applicationId=${appid}`)
  }
  callLetterDownload(id, appid) {
    
    return this.authService.get(`/Candidate/InterviewCallLetterGeneratorWithLogin?id=${id}&applicationId=${appid}`)
  }
  getHallTicketGenerate(acknowldgement){
    return this.authService.get(`/Candidate/AdmitCardSlipDownloadWithoutLogin?AcknowledgementNumber=${acknowldgement}`)
  }

  getCallLetterGenarate(acknowldgement,DateOfBirth) {
    return this.authService.get(`/Candidate/InterviewCallLetterGenerator?RollNo=${acknowldgement}&date=${DateOfBirth} `);
  }
  getPdfStatus(id,appid){
    return this.authService.get(`/candidate/GetStatusDownload?id=${id}&applicationId=${appid}`)
  }

  getattachementsbyid(appid){
    return this.authService.get(`/candidate/GetAttachementsById?applicationId=${appid}`)
  }

  getUrl(){
    return this.authService.getBase();
  }
  // summary page end


  //Get Config
  getConfig(){
    return this.authService.getConfig('/assets/Config.json')
  }

  // Get Jops
  candidateGetJops(){
    return this.authService.get('/Candidate/GetJobs')
  }
  paymentOrderidcheck(id){
    return this.authService.get(`/Candidate/GetCadidatepaymentOrderIdcheck?applicationId=${id}`)
  }
}
