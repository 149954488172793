import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router'
import { environment } from "../../environments/environment";

@Injectable()
export class AuthService {
   url:string = environment.apiAuthUrl;
  constructor(private http: HttpClient,private router: Router) {
  }
getBase(){
  return environment.apiAuthUrl;
}

  post(apiurl:string,body:any){
    
  return this.http.post<any>(this.getBase() + apiurl,body).pipe(map(data => data))
  }
  get(apiurl:string){
    
  return this.http.get<any>(this.getBase() + apiurl).pipe(map(data => data))
  }
  getConfig(apiurl:string){
    
  return this.http.get<any>(apiurl).pipe(map(data => data))
  }
  mediaPost(apiurl:string,body:any){
    
  return this.http.post<any>(this.getBase() + apiurl,body,{headers:{}}).pipe(map(data => data))
  }
  missingmediaPost(apiurl:string,body:any){
    
  return this.http.post<any>(this.getBase() + apiurl,body,{headers:{}}).pipe(map(data => data))
  }
  // Content-Type: image/jpeg


 //Get Users Session
  public getusersession(): any {
    return localStorage.getItem('tjpAppToken');
    
  }
  public setsession(name:string,data:string): any {
   localStorage.setItem(name,data);
  }
  public getsession(name:string): any {
   return localStorage.getItem(name);
   }
  public logout() {
    localStorage.removeItem('tjpAppToken');
    localStorage.removeItem('userid');
    
    this.router.navigate(['login']);
  }

}