import { BrowserModule } from '@angular/platform-browser';
import { NgModule,APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HttpClientModule,HTTP_INTERCEPTORS  } from "@angular/common/http";

import { TranslateModule } from "./translate/index";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './helpers/token.interceptor';
import { AuthService } from './helpers/auth.service';
import { AuthenticationService} from './helpers/AuthenticationService';
import { OtpverifyComponent } from './otpverify/otpverify.component';
import { LayoutComponent } from './layout/layout.component';
import { ToastrModule } from 'ngx-toastr';
import { LoaderServiceComponent } from './loader-service/loader-service.component';
import { LoaderServiceService } from "./loader-service/loader-service.service";
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { AuthGuardService as AuthGuard, AuthLoginService } from './helpers/auth-guard.service';

import { TranslatePipe } from './translate/translate.pipe';
import { TranslateService } from './translate/translate.service';
import { AppSettings } from '../AppSettings';
import { AdmitcardComponent } from './admitcard/admitcard.component';
import { CallletterComponent } from './callletter/callletter.component';
export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use(AppSettings.language);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    OtpverifyComponent,
    LayoutComponent,
    LoaderServiceComponent,
    ForgetPasswordComponent,
    TranslatePipe,
    AdmitcardComponent,
    CallletterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers:[
    TranslateService,{
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    },
    AuthService,
    LoaderServiceService,
    AuthenticationService,
    AuthGuard, AuthLoginService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
