<div class="filter">
    <div class="row m-0 pt-4">
        <div class="col-md-3">
        <label style="font-weight: bold;">Acknowledgement Number : </label>       
            <input type="text" [(ngModel)]="Acknowledgement" class="form-control">
        </div>
        <div class="col-md-1">
            <div class="btn" style="padding: 27px 13px;">
                <button class="btn btn-primary" (click)="generateHallTicket()">Generate</button>
            </div>
        </div>
        <div class="col-md-1">
            <div class="btn" style="padding: 31px 62px;font-weight: bold;">
                <a class="clr_blue cus_a" id="linkClick" target="_blank" href="{{baseUrl}}/Candidate/AdmitCardSlipDownloadWithoutLogin?AcknowledgementNumber={{Acknowledgement}}">Download </a>
            </div>
        </div>
        <div class="col-md-12 mt-4">
           <p><b>Note : </b>  <span class="note_class"> Only After You Generate You Can Download the Admit Card</span></p>
        </div>
    </div>
</div>
