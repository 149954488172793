
<div class="filter">
    <div class="row m-0 pt-4">
        <div class="col-md-3">
        <label style="font-weight: bold;"><sup style="color: red;">*</sup>Roll Number : </label>       
            <input type="text" [(ngModel)]="Acknowledgement" placeholder="Enter Your Roll No" class="form-control">
        </div>
        <div class="col-md-3">
            <label style="font-weight: bold;"><sup style="color: red;">*</sup>Date Of Birth : </label>       
                <input type="date" [(ngModel)]="DateOfBirth" class="form-control p-2">
            </div>
        <div class="col-md-1">
            <div class="btn" style="padding: 27px 13px;">
                <button class="btn btn-primary" (click)="generateCallLetter()">Generate</button>
            </div>
        </div>
        <div class="col-md-1">
            <div class="btn" style="padding: 31px 62px;font-weight: bold;">
                <a class="clr_blue cus_a" id="linkClick" target="_blank" href="{{baseUrl}}/Candidate/DownloadInterviewCallLetter?RollNo={{Acknowledgement}}&date={{DateOfBirth}}">Download </a>
            </div>
        </div>
        <div class="col-md-12 mt-4">
           <p><b>Note : </b>  <span class="note_class"> Only After You Generate You Can Download the Call Letter</span></p>
        </div>
    </div>
</div>
