import { Component, OnInit } from '@angular/core';
import  {AuthService} from '../helpers/auth.service'
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  isShowMobileViewMenus: boolean = false;
  isShowFirstDroupDown: boolean = false;
  isShowSecondDroupDown: boolean = false;
  isShowThirdDroupDown: boolean = false;
  isShowDownloadDroupDown: boolean = false;
  constructor(private authService:AuthService) { }

  ngOnInit(): void {
  }
  logout(){
this.authService.logout();
  }
  showMobileViewMenus(){
    this.isShowMobileViewMenus = !this.isShowMobileViewMenus;
    this.isShowFirstDroupDown = false;
    this.isShowSecondDroupDown = false;
    this.isShowThirdDroupDown = false;
    this.isShowDownloadDroupDown = false;
  }
  showFirstDroupDown(){
    this.isShowFirstDroupDown = !this.isShowFirstDroupDown;
    this.isShowSecondDroupDown = false;
    this.isShowThirdDroupDown = false;
    this.isShowDownloadDroupDown = false;
  }
  showSecondDroupDown(){
    this.isShowSecondDroupDown = !this.isShowSecondDroupDown;
    this.isShowFirstDroupDown = false;
    this.isShowThirdDroupDown = false;
    this.isShowDownloadDroupDown = false;
  }
  showThirdDroupDown(){
    this.isShowThirdDroupDown = !this.isShowThirdDroupDown;
    this.isShowFirstDroupDown = false;
    this.isShowSecondDroupDown = false;
    this.isShowDownloadDroupDown = false;
  }
  showDownloadDroupDown(){
    this.isShowDownloadDroupDown = !this.isShowDownloadDroupDown;
    this.isShowFirstDroupDown = false;
    this.isShowSecondDroupDown = false;
    this.isShowThirdDroupDown = false;
  }

}
