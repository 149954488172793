import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) { }
  canActivate(): boolean {
    const token = localStorage.getItem('tjpAppToken');
    if (!token) {
      localStorage.removeItem('userid')
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}

@Injectable()
export class AuthLoginService implements CanActivate {
  constructor(public router: Router) { }
  canActivate(): boolean {
    const token = localStorage.getItem('tjpAppToken');
    if (token) {
      this.router.navigate(['layout/users/userdashboard']);
      return false;
    }
    return true;
  }
}