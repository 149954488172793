<!-- =========header section========= -->

<section class="header">
    <div class="top">
        <div class="govt_logo">
            <div style="width: fit-content;margin: auto;text-align: center;">
                <img src="assets/images/indianLogo.jpg">
            </div>
            <h4>
                <div>GOVERNMENT OF TRIPURA</div>
                <div> JOINT RECRUITMENT BOARD TRIPURA (JRBT)</div>
                <div>DIRECTORATE OF EMPLOYMENT SERVICES & MANPOWER PLANNING</div>
                <div>AGARTALA, TRIPURA</div>
            </h4>
        </div>
    </div>
</section>

<!-- =========header section end========= -->
<!-- =========body section========= -->
<section class="body">
    <div class="main_cnt">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7 govt_logo left_img">
                    <img src="../../assets/images/loginImg2.jpg" class="mt-3" width="100%" height="230px" alt="">
                    <ul>
                        <div class="waviy">
                            <span style="--i:1">R</span>
                            <span style="--i:2">U</span>
                            <span style="--i:3">L</span>
                            <span style="--i:4">E</span>
                            <span style="--i:5">S</span>
                            <span style="--i:6">.</span>
                            <span style="--i:7">.</span>
                            <span style="--i:8">.</span>
                        </div>
                        <li>Intending candidates should register first in order to fill-up online application for various posts notified by JRBT.</li>
                        <li>Before filling up the online application form the candidates should go through detailed advertisement, corrigendum or Addendum issued by JRBT in <a href=" http://employment.tripura.gov.in">http://employment.tripura.gov.in</a></li>
                        <li>Contact 0381-2326782 for assistance between 10:30 am to 5:00 pm on all working days.</li>
                        <li>For any query or help, you may write us to  jrbtripura.rect2020@gmail.com. Please mention your name and registered mobile no while writing to us.</li>
                    </ul>
                </div>
                <div class="col-md-5 Login_form">
                    <div class="form">
                        <form [formGroup]="otpForm" novalidate>
                            <div class="form-group">
                                <h6>OTP VERIFICATION </h6>
                                <hr />
                                <label class="center-block">
                                    <input type="tel" class="form-control" formControlName="otp"
                                        placeholder="Enter OTP Number" class="form-control" maxlength="6" (keypress)="keyPress($event)">
                                </label>
                                <label style="text-align: center;margin: 7px 0px 0px;">Otp Sent To Your Mail or Phone Number</label>
                                <div *ngIf="otpForm.controls['otp'].invalid && (otpForm.controls['otp'].dirty || otpForm.controls['otp'].touched)" class="alert-danger">
                                    <div *ngIf="otpForm.controls['otp'].errors.required">
                                        OTP Number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="timer">
                                <label>{{countDown | async }}</label>
                            </div>
                            <div class="form-group verifyotp">
                                <button class="btn btn-success" *ngIf="!resendOtp" (click)="finalSubmit()">
                                    <label>Verify OTP</label>
                                </button>
                                <br>
                                <br>
                                <button class="resend_btn" *ngIf="resendOtp" (click)="ChangeResendOtp()">
                                    <label>Resend OTP</label>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>