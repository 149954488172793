import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderServiceService {
  private loading:boolean = false;
  private loadingStatus = new BehaviorSubject(this.loading);
  currentLoginStatus = this.loadingStatus.asObservable();
  constructor() {}  
  startLoading(){
    this.loading = true;
    this.loadingStatus .next(true);
  }
  stopLoading(){
    this.loading = false;
    this.loadingStatus .next(false);
  }
}
