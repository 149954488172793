import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { from } from 'rxjs';
import {AuthService} from './helpers/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tripuraJobPortal';
  isShowLoader = false;
  constructor(private router:Router,public authService:AuthService){
    
  }
  home(){
    this.router.navigateByUrl("/districtdepartment")
  }
}
