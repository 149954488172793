<div class="content_body">
    <!-- =========header section========= -->
    <section class="header">
        <div class="top">
            <div class="govt_logo">
                <div style="width: fit-content;margin: auto;text-align: center;">
                    <img src="assets/images/indianLogo.jpg">
                </div>
                <h4>
                    <div>GOVERNMENT OF TRIPURA</div>
                    <div> JOINT RECRUITMENT BOARD TRIPURA (JRBT)</div>
                    <div>DIRECTORATE OF EMPLOYMENT SERVICES & MANPOWER PLANNING</div>
                    <div>AGARTALA, TRIPURA</div>
                </h4>
                            
                <!-- <a routerLink='/admitcard' style="float: right;font-size: 17px;font-weight:700;cursor:pointer;text-decoration: underline;">Admit Card Download</a>  -->
            </div>
            <div class="flex_grow"></div>
            <div class="cm_pto">
                <!-- <img src="assets/images/Tripura-Chief-Minister.jpg"> -->
            </div>
        </div>
    </section>
    <!-- =========header section end========= -->
    <!-- =========body section========= -->
    <section class="body">
        <div class="main_cnt">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                    
                    <div class="text_marqee">
                        Attention
                    </div>
                        
                    
                </div>
                </div>
                <div class="row" style="background-color: #ECF0F5;">
                    <div class="col-md-8 govt_logo left_img">
                        <img src="../../assets/images/loginImg2.jpg" class="mt-5" width="100%" height="230px" alt="">
                        <div>
                            <ul style="margin: 0;padding-bottom: 0;">
                                <li class="discloser">
                                    <a download=" Format for Reimbursement of Challenge Fee of Answer Keys of JRBT.pdf" target="_blank"
                                        href="/assets/FormatforReimbursementofChallengesFeeofAnswerKeysofJRBT06-Jan-2022 14-11-03.pdf" class="newContent">
                                        Format for Reimbursement of Challenge Fee of Answer Keys of JRBT - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download=" Notice for Reimbursement of Challenge Fee of Answer Keys of JRBT.pdf" target="_blank"
                                        href="/assets/NoticeforReimbursementofChallengesFeeofAnswerKeys06-Jan-2022 14-14-30.pdf" class="newContent">
                                        Notice for Reimbursement of Challenge Fee of Answer Keys of JRBT - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Answer Key for Gr-C(Paper-I) written Examination.pdf" target="_blank"
                                        href="/assets/answersheet/Answer Key for Gr-C(Paper-I) written Examination.pdf" class="newContent">
                                        Answer Key for Group-C (Paper-I) - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Answer Key for Gr-C(Paper-II) Written Examination.pdf" target="_blank"
                                        href="/assets/answersheet/Answer Key for Gr-C(Paper-II) Written Examination.pdf" class="newContent">
                                        Answer Key for Group-C (Paper-II) - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Answer key of Gr-D.pdf" target="_blank"
                                        href="/assets/answersheet/Answer key of Gr-D.pdf" class="newContent">
                                        Answer key of Group-D - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                
                                <li class="discloser">
                                    <a download="Answer Key Challange format Group-C.pdf" target="_blank"
                                        href="/assets/answersheet/Answer Key Challange format Group-C.pdf" class="newContent">
                                        Format for challenging Answer Key for (Group-C) - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                
                                <li class="discloser">
                                    <a download="Format for challenging Answer Key for MTS(Gr-D) written Examination.pdf" target="_blank"
                                        href="/assets/answersheet/Format for challenging Answer Key for MTS(Gr-D) written Examination.pdf" class="newContent">
                                        Format for challenging Answer Key for (Group-D) - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                
                                <li class="discloser">
                                    <a download="JRBT Notification in Bengali.pdf" target="_blank"
                                        href="/assets/answersheet/JRBT Notification in Bengali.pdf" class="newContent">
                                        JRBT Notification in Bengali - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                
                                <li class="discloser">
                                    <a download="JRBT Notification in English.pdf" target="_blank"
                                        href="/assets/answersheet/JRBT Notification in English.pdf" class="newContent">
                                        JRBT Notification in English - <span class="blink_me"> New</span>
                                    </a>
                                </li>
                                
                                <li class="discloser">
                                    <a download="How to Fill-Up OMR Answer Sheet in JRBT written exam.pdf" target="_blank"
                                        href="/assets/How to Fill-Up OMR Answer Sheet in JRBT written exam.pdf" class="newContent">
                                        How to Fill-Up OMR Answer Sheet in JRBT written exam 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Central Strong Room.pdf" target="_blank"
                                        href="/assets/Central Strong Room.pdf" class="newContent">
                                        Central Strong Room 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Information.pdf" target="_blank"
                                        href="/assets/Information.pdf" class="newContent">
                                        Information About Central Strong Room 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Instructions to the Candidates Bengali.pdf" target="_blank"
                                        href="/assets/Instructions to the Candidates Bengali.pdf" class="newContent">
                                        Instructions To The Candidates Of The Written Examination For Group C and Group D Under JRBT.  Bengali 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="Instructions to the Candidates English.pdf" target="_blank"
                                        href="/assets/Instructions to the Candidates English.pdf" class="newContent">
                                        Instructions To The Candidates Of The Written Examination For Group C and Group D Under JRBT.  English
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="GroupC.pdf" target="_blank"
                                        href="/assets/Groupc.pdf" class="newContent">
                                        Attention! JRBT group C exam date out - English 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="GroupD.pdf" target="_blank"
                                        href="/assets/GroupD.pdf" class="newContent">
                                        Attention! JRBT group C exam date out - English 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="GroupC.jpeg" target="_blank"
                                        href="/assets/GroupC.jpeg" class="newContent">
                                        Attention! JRBT group C exam date out - Bengali 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="GroupD.jpeg" target="_blank"
                                        href="/assets/GroupD.jpeg" class="newContent">
                                        Attention! JRBT group D exam date out - Bengali 
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="ExamPospondBengali.pdf" target="_blank"
                                        href="/assets/ExamPospondBengali.pdf" class="newContent">
                                        URGENT- JRBT Exam PostPoned- Bengali
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="ExamPospondEnglish.pdf" target="_blank"
                                        href="/assets/ExamPospondEnglish.pdf" class="newContent">
                                        URGENT- JRBT Exam PostPoned - English
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="AttentionForApplicantsOutsideofTripura.pdf" target="_blank"
                                        href="/assets/AttentionForApplicantsOutsideofTripura.pdf" class="newContent">
                                        Attention For Applicants Out Side Of Tripura
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a download="AttentionpdfinBengali.pdf" target="_blank"
                                        href="/assets/AttentionpdfinBengali.pdf" class="newContent">
                                        Attention For Applicants Out Side Of Tripura (Bengali)
                                    </a>
                                </li>
                                <li class="discloser">
                                    <a (click)="ShowVideos()" class="newContent" style="cursor: pointer;font-size: 18px;">Video on how to fill up OMR answer sheet for written exam for Group-C and Multi Tasking Staff (Group-D) under JRBT</a>
                                    <!-- <p data-toggle="modal" data-target="#myModal">Video on how to fill up OMR answer sheet for written exam for Group-C and Multi Tasking Staff (Group-D) under JRBT</p> -->
                                    <div class="popupbody" *ngIf="isShowVideos">
                                        <div class="popupcontent">
                                            <div class="d-flex">
                                                <i class="fa fa-times" aria-hidden="true" (click)="isShowVideos = false"></i>
                                            </div>
                                            <div class="Video">
                                                <video width="100%" height="auto" controls autoplay>
                                                    <source src="../../assets/FillApplicationViedo.mp4" type="video/mp4">
                                                  </video>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="discloser">
                                    <a download="Notification For Candidates.pdf" target="_blank" href="/assets/instructiontothecandidates.pdf" class="newContent">
                                        NOTIFICATION FOR CANDIDATES
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Extension Of Last Date For Downloading.pdf" target="_blank" href="/assets/Document72.pdf" class="newContent">
                                        Extension Of Last Date For Downloading Admit Card For Gp-C & Gp-D Under JRBT
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="MEMORANDUMOFGROUPCANDGROUPDE-PROVISIONALADMISSIONCERTIFICATE.pdf" target="_blank" href="/assets/OfficeLens.pdf" class="newContent">
                                        MEMORANDUM OF GROUP C AND GROUP D E-PROVISIONAL ADMISSION CERTIFICATE
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Group C Notification.pdf" target="_blank" href="/assets/ExamNotificationofGrpC.pdf" class="newContent">
                                        EXAM NOTIFICATION OF GROUP- C
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Group D Notification.pdf" target="_blank" href="/assets/ExamNotificationofGrpD.pdf" class="newContent">
                                        EXAM NOTIFICATION OF GROUP- D 
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Group C Notification.pdf" target="_blank" href="/assets/SyllabusForGroup-C.pdf" class="newContent">
                                        SYLLABUS FOR GROUP-C COMMON WRITTEN EXAMINATION BY JRBT
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Group D Syllabus.pdf" target="_blank" href="/assets/SyllabusforGroup-D.pdf" class="newContent">
                                        SYLLABUS FOR GROUP-D WRITTEN EXAMINATION BY JRBT
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Addenudum For Group C Exam Syllabus.pdf" target="_blank" href="/assets/ADDENDUMFORGROUP-CEXAMSYLLABUS.pdf" class="newContent">
                                        ADDENDUM FOR GROUP-C EXAM SYLLABUS
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Scheme Of Exam Of Group C.pdf" target="_blank" href="/assets/SCHEMEOFEXAMFORGROUP-C.pdf" class="newContent">
                                        SCHEME OF EXAM OF GROUP C
                                      </a>
                                </li>
                                <li class="discloser">
                                    <a download="Scheme Of Exam Of Group D.pdf" target="_blank" href="/assets/SchemeofExamGroup-D.pdf" class="newContent">
                                        SCHEME OF EXAM OF GROUP D
                                      </a>
                                </li>
                                
                            </ul>
                        </div>
                        <ul>
                            <div class="waviy">
                                <span style="--i:1">R</span>
                                <span style="--i:2">U</span>
                                <span style="--i:3">L</span>
                                <span style="--i:4">E</span>
                                <span style="--i:5">S</span>
                                <span style="--i:6">.</span>
                                <span style="--i:7">.</span>
                                <span style="--i:8">.</span>
                            </div>
                            <li>The syllabus for Group-C & Group-D examination by JRBT has been uploaded in Directorate of Employment Services & Manpower planning website <a href="https://employment.tripura.gov.in/" target="blank">https://employment.tripura.gov.in/</a> </li>
                            <li>The last date of filling up of online application for 2410 Group C post (1500 LDC, 450Agri. Assistant (TAFS Grade-III and Except TAFS Grade-III), 236 Junior Operator (Pump) and 209 Junior Multi Tasking Operator (Un-common) is on 12th March,2021</li>
                            <li>১৫০০  এল  ডি  সি, ৪৬৫  এগ্রি  অ্যাসিস্ট্যান্ট, ২০৯  জুনিয়র  মাল্টি  টাস্কিং  ক্লার্ক  এবং  ২৩৬  জুনিয়র  অপারেটর  (পাম্প)  (গ্রুপ – সি)  পদে  অনলাইন  আবেদনের  শেষ  তারিখ  ১২ই  মার্চ  ২০২১।</li>
                            <li>As it is a common application, those candidates who have already applied for 1500 posts of LDC need not apply for other Group-C posts and their candidature will be considered for all 2410 GroupC posts based on their Educational qualification</li>
                            <li>যেহেতু  এটি  কমন  বিজ্ঞাপন, তাই  যারা  ১৫০০  এল   ডি   সি  পদে আবেদন   করেছে  তাদের  আর  অন্যান্য গ্রুপ – সি পদে আবেদন  করতে হবে না ।  শিক্ষাগত যোগ্যতার ভিত্তিতে ২৪১০ গ্রুপ – সি পদে বিবেচিত  হবে।</li>
                            <li>Intending candidates should register first in order to fill-up online application for various posts notified by JRBT.</li>
                            <li>Before filling up the online application form the candidates should go through detailed advertisement, corrigendum or Addendum issued by JRBT in <a href=" http://employment.tripura.gov.in" target="_blank">http://employment.tripura.gov.in</a></li>
                            <li>Contact 0381-2326782 for assistance between 10:30 am to 5:00 pm on all working days.</li>
                            <li>For any query or help, you may write us to  jrbtripura.rect2020@gmail.com. Please mention your name and registered mobile no while writing to us.</li>

                        </ul>
                        <!-- <img src="assets/images/loginImg.jpg" width="100%"> -->
                        <!-- <div class="side_logo">
                            <img src="assets/images/tripura_symbol.png">
                            <div class="Logo_title">
                                <h3>Tripura State Job Portal</h3>
                                <p>Government of Tripura</p>
                            </div>
                        </div>
                        <p>An Online Assessment Portal - A Significant Technological Advancement to upgrade the LOWER DIVISION CLERK (L.D.C) Examination System</p> -->
                    </div>
                    <div class="col-md-4 Login_form">
                        <div class="form">
                            <h6 class="head_title">NEW USER REGISTER</h6>
                            <hr>
                            <form [formGroup]="registertForm" novalidate (ngSubmit)="registerSubmit()">
                                <!-- <div class="form-group">
                                    <select #lng (change)="changed(lng.value)" class="form-control">
                                        <option *ngFor="let lang of languageData.i18n" value="{{lang.key}}">{{lang.name}}</option>
                                    </select>
                                </div> -->
                                <div class="form-group">
                                    <label class="center-block">{{'Name' | translate}}: <span style="color:#ff0000">*</span>
                                        <input class="form-control " formControlName="fname" 
                                            placeholder="Type Your Full Name"  maxlength="100" (keyup)="nameupper()" (keypress)="KeyPressevent($event)">
                                    </label>
                                    <div *ngIf="registertForm.controls['fname'].invalid && (registertForm.controls['fname'].dirty || registertForm.controls['fname'].touched) || registertForm.controls['fname'].hasError('pattern')" class="alert-danger">
                                        <div *ngIf="registertForm.controls['fname'].errors.required ">
                                            Name is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['fname'].hasError('pattern') || registertForm.controls['fname'].hasError('minlength') || registertForm.controls['fname'].hasError('maxlength') ">
                                            Valid Name is required.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="center-block">{{'Email ID' | translate}}:
                                        <!-- <span style="color:#ff0000">*</span> -->
                                        <input type="email"  class="form-control" formControlName="emailid"
                                        placeholder="Type Your Email ID" (keyup)="change()" maxlength="100">    
                                    </label>
                                    <div *ngIf="registertForm.controls['emailid'].invalid && (registertForm.controls['emailid'].dirty || registertForm.controls['emailid'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['emailid'].errors.required">
                                            Email ID is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['emailid'].errors.email || registertForm.controls['emailid'].hasError('pattern')">
                                            Email must be a valid email address
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="center-block">{{'Mobile Number' | translate}}:<span style="color:#ff0000">*</span>
                                        <input type="tel" class="form-control" formControlName="mobile"
                                            placeholder="Type Your 10 Digit Mobile Number" class="form-control"
                                            minlength="10" maxlength="10" (keypress)="keyPress($event)">
                                    </label>
                                    <div *ngIf="registertForm.controls['mobile'].invalid && (registertForm.controls['mobile'].dirty || registertForm.controls['mobile'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['mobile'].errors.required">
                                            Mobile Number is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['mobile'].hasError('pattern')">
                                            Valid Mobile Number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="center-block">{{'Alternate Mobile Number' | translate}}:
                                        <input type="tel" class="form-control" formControlName="alternatemobile"
                                            placeholder="Type Your Alternative Mobile Number" class="form-control"
                                            maxlength="10" (keypress)="keyPress($event)">
                                    </label>
                                    <div *ngIf="registertForm.controls['alternatemobile'].invalid && (registertForm.controls['alternatemobile'].dirty || registertForm.controls['alternatemobile'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['alternatemobile'].errors.required">
                                            Mobile Number is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['alternatemobile'].hasError('pattern')">
                                            Valid Mobile Number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="center-block">{{'Date Of Birth' | translate}}:<span style="color:#ff0000">*</span>
                                        <input type="date" class="form-control" [max]="maxDate" (keydown) = "false" formControlName="dob" class="form-control">
                                    </label>
                                    <div *ngIf="registertForm.controls['dob'].invalid && (registertForm.controls['dob'].dirty || registertForm.controls['dob'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['dob'].errors.required">
                                            Date of Birth is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="center-block">{{'Password' | translate}}:<span style="color:#ff0000">*</span>
                                        <input type="password"  formControlName="password"
                                            placeholder="Type Your Password" class="form-control" maxlength="12">
                                        <label style="pointer-events: none;">{{'Note' | translate}}</label>
                                    </label>
                                    <div *ngIf="registertForm.controls['password'].invalid && (registertForm.controls['password'].dirty || registertForm.controls['password'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['password'].errors.required">
                                            Password is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['password'].errors.minlength">
                                            Password must be at least 8 characters
                                        </div>
                                        <div *ngIf="registertForm.controls['password'].errors.pattern">
                                            Valid Password is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="center-block">{{'Confirm Password' | translate}}:<span style="color:#ff0000">*</span>
                                        <input type="password" class="form-control" formControlName="confirmPassword"
                                            placeholder="Re-Type Your Password" maxlength="12">
                                    </label>
                                    <div *ngIf="registertForm.controls['confirmPassword'].invalid && (registertForm.controls['confirmPassword'].dirty || registertForm.controls['confirmPassword'].touched)" class="alert-danger">
                                        <div *ngIf="registertForm.controls['confirmPassword'].errors.required">
                                            Confirm Password is required.
                                        </div>
                                        <div *ngIf="registertForm.controls['confirmPassword'].errors.mustMatch">
                                            Passwords must match</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" [disabled]="registertForm.pristine || registertForm.invalid" class="btn btn-success">
                                        REGISTER
                                    </button>
                                </div>
                            </form>
                            <hr>
                            <p class="nw_reg" routerLink='/login'><i>Already Have an Account?</i></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- =========body section end========= -->
</div>