import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {AuthenticationService} from '../helpers/AuthenticationService';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "../translate/translate.service";
import json from '../../assets/i18n/config.json';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registertForm: FormGroup;

  currentDate = new Date();
  minYear = this.currentDate.getFullYear() - 42;
  minDate = this.minYear.toString()+"-01-01";
  maxYear = this.currentDate.getFullYear()+1;
  maxDate = this.maxYear.toString()+"-12-31";
  languageData:any;
 
  constructor(private fb: FormBuilder,private authenticationService:AuthenticationService,private router: Router, private toastr: ToastrService, private translate: TranslateService) {
    this.RegisterForm();
    this.languageData = json;
    this.maxDate;
    this.minDate;
   
  }

  ngOnInit(): void {
    
  }
  setLang(lang: string) {
    this.translate.use(lang);

    
  }

  RegisterForm() {
    this.registertForm = this.fb.group({
    
      fname: ['', [Validators.required,Validators.minLength(3),Validators.maxLength(70)]],
      emailid: ['', [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],//Validators.required,
      mobile: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],
      alternatemobile: ['', Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)],
      dob: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: this.MustMatch('password', 'confirmPassword')
      })
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

 registerSubmit() {
    
    let mobile = parseInt(this.registertForm.value.mobile);
  let altermob = parseInt(this.registertForm.value.alternatemobile);
  if ( mobile == altermob) {
    this.toastr.warning ('Mobile Number and Alternate Should Not be Same','Registration')
    return;
  }
    if (this.registertForm.invalid) {
      return;
  }
  
    let body={
      firstName:this.registertForm.value.fname,
      // middleName:this.registertForm.value.mname ,
      // lastName: this.registertForm.value.lname,
      emailAddress: this.registertForm.value.emailid,
      mobileNumber: this.registertForm.value.mobile,
      alternateMobileNumber: this.registertForm.value.alternatemobile!=''?this.registertForm.value.alternatemobile:null,
      dob: this.registertForm.value.dob,
      password: this.registertForm.value.password
    }
    this.authenticationService.postRegister(body)
    .subscribe({
      next: (res) => {
        if (res.statusCode==200) {
          this.toastr.success(res.message, 'Registration');
         // this.router.navigate([`otpverify`],{state:{data:this.registertForm.value.mobile,pagename:"register",password:"",dept:false}})//,skipLocationChange: true
         this.router.navigate([`login`]);
        }
        else if(res.statusCode==208)
        {
          this.toastr.warning(res.message,'Registation');
        }
      },
      error: error => {
      this.toastr.error(error.statusText,'Registation');
      }
    });
  }



  changed(value) {
    
    this.setLang(value)
  }


  keyPress(e){
    var keycode = e.which;
      if (!(e.shiftKey == false && (keycode == 46 || keycode == 8 || keycode == 37 || keycode == 39 || (keycode >= 48 && keycode <= 57)))) {
          e.preventDefault();
      }
  }

  KeyPressevent(e){
    var keycode = e.which;
    if(!(e.shiftKey == false && (keycode > 64 && keycode < 91 || (keycode > 96 && keycode < 123)|| keycode == 8 || keycode == 32 || (keycode >= 48 && keycode <= 57 ) && (keycode >= 65 && keycode <= 120) && (keycode != 32 && keycode != 0))))
{
  e.preventDefault();
} 
}

Blurtrim(){
  this.registertForm.controls["fname"].setValue(this.registertForm.value.fname.trim());
}

change() {
 
  this.registertForm.controls["emailid"].setValue(this.registertForm.value.emailid.toLowerCase()) ;
}
nameupper(){
  this.registertForm.controls["fname"].setValue(this.registertForm.value.fname.toUpperCase());
}

// show video start
isShowVideos = false;
ShowVideos(){
  this.isShowVideos = true;
}
}
